var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("t3-dynamic", {
        attrs: {
          data: _vm.t3page.content,
          type: _vm.t3page.appearance.backendLayout,
          layout: "",
        },
      }),
      _vm._v(" "),
      _c(
        "transition",
        {
          attrs: {
            "enter-active-class": "transition-all duration-500 ease opacity-0",
            "enter-to-class": "opacity-100",
            "leave-active-class": "duration-200 ease-in",
            "leave-class": "opacity-100",
            "leave-to-class": "opacity-0",
          },
        },
        [
          _vm.modal !== null
            ? _c(
                "div",
                {
                  staticClass:
                    "fixed z-[1100] bg-black/30 backdrop-blur-sm inset-0 modal",
                  on: {
                    click: function ($event) {
                      if ($event.target !== $event.currentTarget) return null
                      return _vm.closeModal()
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "fixed inset-0 md:inset-x-12 md:top-1/2 md:-translate-y-1/2 lg:min-h-fit max-h-screen max-w-screen-xl mx-auto bg-secondary md:rounded-md md:drop-shadow-2xl z-100 overflow-y-auto moz-fix",
                    },
                    [
                      _c("button", {
                        staticClass:
                          "block ml-auto before:block before:content-close before:w-6 before:h-6 before:m-4 hover:before:rotate-90 before:transition-all before:duration-300",
                        on: { click: _vm.closeModal },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "mx-10 no-image-decoration" },
                        [
                          _vm.modal.content
                            ? _c("t3-dynamic", {
                                attrs: {
                                  data: _vm.modal.content,
                                  type: "modal",
                                  layout: "",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }