<template>
  <div>
    <t3-dynamic
      :data="t3page.content"
      :type="t3page.appearance.backendLayout"
      layout
    />
    <transition
      enter-active-class="transition-all duration-500 ease opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="duration-200 ease-in"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div v-if="modal !== null" class="fixed z-[1100] bg-black/30 backdrop-blur-sm inset-0 modal" @click.self="closeModal()">
        <div class="fixed inset-0 md:inset-x-12 md:top-1/2 md:-translate-y-1/2 lg:min-h-fit max-h-screen max-w-screen-xl mx-auto bg-secondary md:rounded-md md:drop-shadow-2xl z-100 overflow-y-auto moz-fix">
          <button class="block ml-auto before:block before:content-close before:w-6 before:h-6 before:m-4 hover:before:rotate-90 before:transition-all before:duration-300" @click="closeModal"></button>
          <div class="mx-10 no-image-decoration">
            <t3-dynamic
              v-if="modal.content"
              :data="modal.content"
              type="modal"
              layout
            />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import defaultPage from '~typo3/mixins/page/default'
export default {
  name: 'DefaultPage',
  mixins: [defaultPage],
  async beforeRouteUpdate (to, from, next) {
    const request = await this.$axios.get (to.path)
    const result = request.data
    if (result && result.appearance.layout === 'layout-1') {
      this.openModal (result, from.path, to.path)
    } else {
      this.modal = null
      next ()
    }
  },
  data () {
    return {
      modal: null,
      scrollDistance: 0,
    }
  },
  mounted () {
    const message = '🕵️ Zurück zu sure[secure]'
    let original = document.title
    window.onblur = function () {
      original = document.title
      document.title = message
    }
    window.onfocus = function () {
      document.title = original
    }
  },
  methods: {
    openModal(data) {
      this.modal = null
      this.$emit('close-nav')
      this.modal = data
      const $body = document.querySelector('body')
      this.scrollDistance = document.documentElement.scrollTop
      $body.style.position = 'fixed'
      $body.style.top = this.scrollDistance*(-1)+'px'
    },
    closeModal() {
      this.modal = null
      const $body = document.querySelector('body')
      $body.style.position = ''
      $body.style.top = ''
      document.documentElement.scrollTo({ top: this.scrollDistance, behavior: 'instant' })
    }
  }
}
</script>
<style lang="postcss" scoped>
.moz-fix {
  height: -moz-max-content;
}
</style>